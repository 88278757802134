import axios from 'axios';
import { url } from "../../api";

export const fetchUncollectedBadges = () => {
    return async (dispatch) => {
      try {
        const response = await axios.get(`${url}/api/badges/unconfirmed`, {
          headers: {
            'x-auth-token': localStorage.getItem('token'),
          },
        });
        if (response.status === 200) {
          dispatch({
            type: 'FETCH_UNCOLLECTED_BADGES_SUCCESS',
            badges: response.data,
          });
        }
      } catch (error) {
        console.error('Error fetching uncollected badges:', error);
        dispatch({
          type: 'FETCH_UNCOLLECTED_BADGES_ERROR',
          error: error.message,
        });
      }
    };
  };

  
  export const collectBadge = (badgeId) => {
    return async (dispatch) => {
      try {
        const response = await axios.post(
          `${url}/api/badges/confirm`,
          {"badgeCode": badgeId},
          {
            headers: {
              'x-auth-token': localStorage.getItem('token'),
            },
          }
        );
        if (response.status === 200) {
          dispatch({
            type: 'COLLECT_BADGE_SUCCESS',
            badgeId,
          });
          // Optionally, you can fetch the updated list of uncollected badges
          dispatch(fetchUncollectedBadges());
        }
      } catch (error) {
        console.error('Error collecting badge:', error);
        dispatch({
          type: 'COLLECT_BADGE_ERROR',
          error: error.message,
        });
      }
    };
  };
  