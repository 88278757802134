import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { url as apiUrl, setHeaders } from '../../../api';

const AssignBadgesButton: React.FC = () => {
  // state of the response from the server
  const [response, setResponse] = React.useState<any | null>(null);

  const handleAssignBadges = async () => {
    try {
      const headers = setHeaders();
      const response = await axios.get(`${apiUrl}/api/badges/admin/assign-badges`, headers);
      if (response.status === 200) {
        toast.success('Badges assigned successfully!');
        setResponse(response.data);
        console.log('Users with badges:', response.data);
      }
    } catch (error) {
      console.error('Error assigning badges:', error);
      toast.error('Failed to assign badges');
    }
  };

  return (
    <button
      onClick={handleAssignBadges}
      className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
    >
      Assign Badges
      {response && (
        <div>
          <h2>Users with badges:</h2>
          <ul>
            {response.map((user: any) => (
              <li key={user._id}>{user.username}</li>
              // score:
            ))}
          </ul>
        </div>
      )}
    </button>
  );
};

export default AssignBadgesButton; 