// components/badges/badges_page/BadgeRewardOverlay.js
import React from 'react';

interface BadgeRewardOverlayProps {
	name: string;
	code: string;
	description: string;
	points: number;
	imageUrl: string;

	onClose(): void;
}

const BadgeRewardOverlay: React.FC<BadgeRewardOverlayProps> = ({ name, code, description, points, imageUrl, onClose }) => {
	return (
		<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
			<div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full relative">
				<div className="flex flex-col items-center">
					<img src={imageUrl} alt={name} className="w-60 h-60 mb-4" />
					<h2 className="text-2xl font-bold mb-2">{name}</h2>
					<p className="text-gray-700 mb-4">{description}</p>
					<span className="text-lg font-semibold text-green-600 mb-4">
						+{points} Points
					</span>
					<button
						onClick={onClose}
						className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
					>
						Collect
					</button>
				</div>
			</div>
		</div>
	);
};

export default BadgeRewardOverlay;
