const initialState = {
    uncollectedBadges: [],
    error: null,
};

const badgeReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_UNCOLLECTED_BADGES_SUCCESS':
        return {
          ...state,
          uncollectedBadges: action.badges,
        };
      case 'FETCH_UNCOLLECTED_BADGES_ERROR':
        return {
          ...state,
          error: action.error,
        };
      case 'COLLECT_BADGE_SUCCESS':
        return {
          ...state,
          uncollectedBadges: state.uncollectedBadges.filter(
            (badge) => badge._id !== action.badgeId
          ),
        };
      case 'COLLECT_BADGE_ERROR':
        return {
          ...state,
          error: action.error,
        };
      default:
        return state;
    }
  };
  
export default badgeReducer; 