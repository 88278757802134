import React from 'react';
import { RecipeInfo } from '../loaders/useInfo';
import '../styles/recipe_make.css';
import { url as apiUrl, setHeaders } from '../../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCertificate, faCheck, faCheckCircle, faCircleCheck, faEye, faIdBadge } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/types';
import axios from 'axios';
import { toast } from 'react-toastify';

export type RecipeMake = {
	userId: string;
	id: string;
	author: string;
	comment: string;
	date: Date;
	image: string;
	isConfirmed: boolean;
}

const RecipeMakeCard: React.FC<{recipeInfo: RecipeInfo, recipeMake: RecipeMake }> = ({ recipeInfo, recipeMake }) => {
	const user = useSelector((state: RootState) => state.auth);

	const handleConfirm = async () => {
		try{
			const headers = setHeaders();
			const res = await axios.put(`${apiUrl}/api/recipes/${recipeInfo.id}/comments/${recipeMake.id}/confirm`, {}, headers);
			if (res.status !== 200) {
				throw new Error('Failed to confirm comment');
			}
			toast.success('Comment confirmed');
		} catch (error) {	
			console.error('Error confirming comment:', error);	
			toast.error('Failed to confirm comment');
		}
	};

	return (
		<div className='im_res_div_make'>
			<div className="recipe-make-user-small">{recipeMake.author}</div>
			<img src={`${apiUrl}/api/recipes/images/${recipeInfo.id}/${recipeMake.image}`} alt={recipeMake.author} className="recipe-make-thumbnail" />

			<div className="recipe-make-content">
				<div>
					
					<div className="recipe-make-comment-small">
						{recipeMake.comment}{recipeMake.isConfirmed && <span className='text-blue-400 text-xs border text-nowrap rounded-full p-0.5'
						><FontAwesomeIcon className= "pr-0.5" icon={faCircleCheck} />Confirmed</span>}
						
					</div>
				</div>
				{user.role === 'admin' && !recipeMake.isConfirmed && (
					<button onClick={handleConfirm}>Confirm Comment</button>
				)}
			</div>
			{/* text to the bottom left 0.5rem */}
			<span className="text-xs
			text-gray-400 text-left ">{recipeMake.date.toDateString()}</span>
		</div>
	);
};


export default RecipeMakeCard;
