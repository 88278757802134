import { combineReducers } from "redux"

import galleryReducer from "./galleryReducer";
import authReducer from "./authReducer";
import chatReducer from "./chatReducer";
import badgeReducer from "./badgeReducer";

const rootReducer = combineReducers({
	galleries: galleryReducer,
	auth: authReducer,
	chat: chatReducer,
	badges: badgeReducer,
})

export default rootReducer