import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BadgeRewardOverlay from './badgeRewardOverlay'; // Ensure correct casing
import { collectBadge, fetchUncollectedBadges } from '../../../store/actions/badgeActions';
import axios from 'axios';
import { url as apiUrl, setHeaders } from '../../../api'; // Adjust the path as needed
import { toast } from 'react-toastify';

const BadgeOverlayWrapper = () => {
  const dispatch = useDispatch();
  const uncollectedBadges = useSelector((state) => state.badges.uncollectedBadges);
  const [imageUrl, setImageUrl] = useState(null);

  const currentBadge = uncollectedBadges && uncollectedBadges.length > 0 ? uncollectedBadges[0] : null;

  useEffect(() => {
    let isMounted = true; // To prevent state updates after unmounting
    let imageObjectUrl = null;

    const fetchImage = async (code) => {
      try {
        const headers = setHeaders({responseType: 'blob'});
        const response = await axios.get(`${apiUrl}/api/badges/badge-image/${code}`, 
          headers,
          );
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        imageObjectUrl = URL.createObjectURL(blob);
        if (isMounted) {
          setImageUrl(imageObjectUrl);
        }
      } catch (error) {
        console.error('Error fetching badge image:', error);
        toast.error('Failed to fetch badge image');
      }
    };

    if (currentBadge && currentBadge.badge.code) {
      fetchImage(currentBadge.badge.code);
    } else {
      setImageUrl(null); // Reset imageUrl if there's no current badge
    }

    // Cleanup function
    return () => {
      isMounted = false;
      if (imageObjectUrl) {
        URL.revokeObjectURL(imageObjectUrl);
      }
    };
  }, [currentBadge, apiUrl, setHeaders]); // Removed imageUrl from dependencies

  const handleClose = (badgeCode) => {
    dispatch(collectBadge(badgeCode));
    setImageUrl(null); // Reset imageUrl for the next badge
  };

  // Conditional rendering after hooks
  if (!currentBadge || !imageUrl) {
    return null; // Or display a loading indicator
  }

  const badgeProps = {
    name: currentBadge.badge.name,
    code: currentBadge.badge.code,
    description: currentBadge.badge.description,
    points: currentBadge.badge.value,
    imageUrl: imageUrl,
  };

  return (
    <BadgeRewardOverlay
      {...badgeProps}
      onClose={() => handleClose(currentBadge.badge.code)}
    />
  );
};

export default BadgeOverlayWrapper;
