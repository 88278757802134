import React, { Component } from "react";
import UserInfo from "./stats-box";
import EditableUserInfo from "./stats-box-edit";
import UserFilter from "./filter";
import { url } from "../../api/index.js";
import styled from "styled-components";
import FullStatsBox from "./full-stats-box";
import FullStats from "./big-stats-box";
import AotdAuthorsLeaderboard from "./AOTDLeaderboard";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #c9febe;
  align-items: center;
  padding: 15px;
  height: auto;
  min-height: 100vh;
`;

interface Stats {
  totalUsers: number;
  totalFriendsFamilyUsers: number;
  totalAOTD: number;
  totalMessages: number;
  totalTokensUsed: number;
  usersWithTheMostTokens: Array<any>;
  aotdAuthors: Array<any>;
}


interface User {
  id: number;
  username: string;
  userEmoji: string;
  email: string;
  role: string;
  dateSigned: string;
  lastSigned: string;
  timesSigned: number;
  chatTokensUsed: number;
  permissions: number;
}

interface UserListState {
  users: User[];
  editingUserIds: Set<number>;
  editedUsernames: string[];
}

class UserList extends Component<{}, UserListState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      users: [], // Initialize with an empty array
      editingUserIds: new Set(),
      editedUsernames: [],
    };
  }


  componentDidMount() {
    // Fetch users from the server and update state
    this.fetchUsers();
  }

  async fetchUsers() {
    // Replace the URL with your API endpoint
    const token = localStorage.getItem("token");
    const headers: HeadersInit = {
      ...(token ? { "x-auth-token": token } : {}),
    };

    const response = await fetch(`${url}/api/stats/all-users`, { headers });
    if (response.status !== 200) {
      return
    }
    const users = await response.json();
    this.setState({ users });
  }

  // user stats form /api/stats/users
  // return a dict of users and their stats
  async fetchUserStats() {
    try {
      const token = localStorage.getItem("token");
      const headers: HeadersInit = {
        ...(token ? { "x-auth-token": token } : {}),
      };

      const response = await fetch(`${url}/api/stats/users`, { headers });
      if (response.status !== 200) {
        return
      }
      const userStats = await response.json();
      // the format is:
      // return {
      //   username: user.username,
      //   quoteCount: userQuoteCount ? userQuoteCount.quotesCount : 0,
      //   longestStreak: {
      //     "count": userStreak ? userStreak.longestStreak : 0,
      //     streakStartDate: userStreak ? userStreak.startDate : null,
      //     streakEndDate: userStreak ? userStreak.endDate : null
      //   }
      //   };
      //   });
      return userStats;
    } catch (error) {
      console.error("Error fetching user stats:", error);
    }
  }

  handleFilterChange = async (filterData: any) => {
    const { searchText } = filterData;
    const queryParams = new URLSearchParams();
    if (searchText) {
      queryParams.append("username", searchText);
    }
    // Add other filter parameters to queryParams if needed

    const response = await fetch(
      `https://api.example.com/users?${queryParams.toString()}`
    );
    const users = await response.json();
    this.setState({ users });
  };


  handleEditButtonClick = (username: string) => {
    this.setState((prevState) => ({
      editedUsernames: [username]
    }));
  };

  handleCancelEdit = (username: string) => {
    const editedUsernames = this.state.editedUsernames.filter(
      (element) => element !== username
    );
    this.setState({ editedUsernames });
  };


  handleSubmit = async (username: string, updatedUserData: any) => {
    // Submit updated user data to the server
    const token = localStorage.getItem("token");
    await fetch(`${url}/api/stats/user/${username}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token as string,
      },
      body: JSON.stringify(updatedUserData),
    });

    // Refetch the user list and reset the editing user IDs
    await this.fetchUsers();
    const editedUsernames = this.state.editedUsernames.filter(
      (element) => element !== username
    );
    this.setState({ editedUsernames });
    // this.setState({ editingUserIds: new Set() });
  };

  render() {
    return (
      <PageContainer>
        <FullStats />
        <AotdAuthorsLeaderboard />
        {/* <UserFilter onFilterChange={this.handleFilterChange} /> */}
        {this.state.users.map((user) => {
          if (this.state.editedUsernames.includes(user.username)) {
            return (
              <EditableUserInfo
                key={user.id}
                username={user.username}
                userEmoji={user.userEmoji}
                email={user.email}
                role={user.role}
                dateSigned={user.dateSigned}
                lastSigned={user.lastSigned}
                timesSigned={user.timesSigned}
                chatTokensUsed={user.chatTokensUsed}
                permissions={user.permissions}
                onSubmit={(updatedUserData) =>
                  this.handleSubmit(user.username, updatedUserData)
                }
                onCancel={() => this.handleCancelEdit(user.username)}
              />
            );
          } else {
            return (
              <UserInfo
                key={user.id}
                username={user.username}
                userEmoji={user.userEmoji}
                email={user.email}
                role={user.role}
                dateSigned={user.dateSigned}
                lastSigned={user.lastSigned}
                timesSigned={user.timesSigned}
                chatTokensUsed={user.chatTokensUsed}
                permissions={user.permissions}
                onEditButtonClick={() =>
                  this.handleEditButtonClick(user.username)
                }
              />
            );
          }
        })}
      </PageContainer>
    );
  }
}
export default UserList;
