import axios from "axios";
import { url } from "../../api";
import { toast } from "react-toastify";
import { getMessages } from "./chatActions";

export const signUp = (user) => {
  return (dispatch, getState) => {
    const statee = getState();
    console.log(`${url}/signup`);
    axios
      .post(`${url}/api/signup`, user)
      .then((token) => {
        localStorage.setItem("token", token.data);

        dispatch({
          type: "SIGNUP",
          token: token.data,
        });
      })
      .catch((error) => {
        const errorMessage = error.response?.data 
        dispatch({
          type: "ERROR",
          error: errorMessage,
        });
      });
  };
};

export const changeCredentials = (updatedUserData) => {
  return (dispatch, getState) => {
    const statee = getState();
    const username = statee.auth.username;
    axios
      .put(`${url}/api/edit/userself/${username}`, updatedUserData, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
      .then((token) => {
        localStorage.setItem("token", token.data);

        dispatch({
          type: "CHANGE_CREDS",
          token: token.data,
        });
        toast(`credentials changed!`, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response?.data.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };
};


export const signIn = (creds) => {
  return (dispatch, getState) => {
    const statee = getState();
    console.log(`${url}/signin`);
    axios
      .post(`${url}/api/signin`, creds)
      .then((token) => {
        localStorage.setItem("token", token.data);
        getMessages();
        dispatch({
          type: "SIGNIN",
          token: token.data,
        });
      })
      .catch((error) => {
        const errorMessage = error.response?.data 
        dispatch({
          type: "ERROR",
          error: errorMessage,
        });
      });
  };
};

export const signOut = () => {
  return {
    type: "SIGNOUT",
  };
};

export const loadUser = () => {
  return (dispatch, getState) => {
    const token = getState().auth.token;
    if (token) {
      dispatch({
        type: "USER_LOAD",
        token,
      });
    } else return null;
  };
};