import React, { useEffect, useState } from 'react';
import BadgeRewardOverlay from './badgeRewardOverlay';
import { url as apiUrl, setHeaders } from '../../../api';
import axios from 'axios';
import { toast } from 'react-toastify';
import BadgeCard from './badgeCard';

interface BadgeInfo {
  name: string;
  description: string;
  points: number;
  isUnique: boolean;
  color: string;
  collected: boolean;
  dateEarned: string;
  badgeId: string;
  badgeCode: string;
}

const BadgeListing: React.FC = () => {
  const [badges, setBadges] = useState<BadgeInfo[]>([]);

  const fetchBadges = async () => {
    try {
      const headers = setHeaders();
      const response = await axios.get(`${apiUrl}/api/badges/all`, headers);

      const fetchedBadges = response.data.map((badgeItem: any) => ({
        name: badgeItem.badge.name,
        description: badgeItem.badge.description,
        points: badgeItem.badge.value,
        color: badgeItem.badge.color,
        isUnique: badgeItem.badge.isUnique,
        collected: badgeItem.collected,
        dateEarned: badgeItem.dateEarned,
        badgeId: badgeItem._id,
        badgeCode: badgeItem.badge.code,
      }));

      setBadges(fetchedBadges);
    } catch (error) {
      console.error('Error fetching badges:', error);
      toast.error('Failed to fetch badges');
    }
  };

  useEffect(() => {
    fetchBadges();
  }, []);

  return (
    <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-6 gap-2 px-2">
      {badges
        .sort((a, b) => b.points - a.points)
        .map((badge) => (
          <div
            key={badge.badgeId}
            className="p-1 sm:p-2 lg:p-3 flex items-center justify-start" // Left-align content
          >
            <BadgeCard
              name={badge.name}
              points={badge.points}
              dateEarned={badge.dateEarned}
              description={badge.description}
              isUnique={badge.isUnique}
              code={badge.badgeCode}
              // imageUrl={badge.imageUrl} // Pass imageUrl if available
            />
          </div>
        ))}
    </div>  

  );
};

export default BadgeListing;
