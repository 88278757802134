import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { url as apiUrl, setHeaders } from '../../../api'; // Adjust this import
import AssignBadgesButton from './assignBadgesButton';

interface BadgeInterface {
	_id: string;
	name: string;
	description: string;
	code: string;
	category: string;
	value: number;
	color: string;
	iconPath: string;
	dateCreated: string; // Date as string
	isUnique?: boolean;
}

const BadgeAdminPage: React.FC = () => {
	const [csvFile, setCsvFile] = useState<File | null>(null);
	const [badges, setBadges] = useState<BadgeInterface[]>([]);
	const [badgeImages, setBadgeImages] = useState<{ [key: string]: File | null }>({});
	const [badgeImagesUrls, setBadgeImagesUrls] = useState<{ [key: string]: string }>({});

	// Fetch badges on component mount
	useEffect(() => {
		const fetchBadges = async () => {
			try {
				const headers = setHeaders();
				const response = await axios.get(apiUrl + '/api/badges/admin/badges', headers);
				setBadges(response.data);
			} catch (error) {
				console.error('Error fetching badges:', error);
				toast.error('Failed to fetch badges');
			}
		};

		fetchBadges();
	}, []);

	// fetch image with headers
	const fetchBadgeImage = async (badge: BadgeInterface) => {
		try {
			const headers = setHeaders();
			const response = await axios.get(`${apiUrl}/api/badges/admin/badge-image/${badge.code}`, 
				{ ...headers, responseType: 'blob' }
			);
			const imageUrl = URL.createObjectURL(response.data);
			setBadgeImagesUrls(prevState => ({
				...prevState,
				[badge._id]: imageUrl
			}));
		} catch (error) {
			console.error('Error fetching image for ' + badge.name + ':', error);
			toast.error('Failed to fetch badge image for ' + badge.name);
		}
	};

	useEffect(() => {
		badges.forEach(badge => {
			fetchBadgeImage(badge);
		});
	}, [badges]);

	// Handle CSV file selection
	const handleCsvChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			setCsvFile(e.target.files[0]);
		}
	};

	// Handle CSV upload
	const handleCsvUpload = async () => {
		if (!csvFile) {
			toast.error('Please select a CSV file');
			return;
		}

		const formData = new FormData();
		formData.append('file', csvFile);

		try {
			const headers = setHeaders({'Content-Type': 'multipart/form-data'});
			await axios.post(apiUrl + '/api/badges/admin/upload-badges', formData, 
				headers
			);
			toast.success('Badges uploaded successfully');
		} catch (error) {
			console.error('Error uploading CSV:', error);
			toast.error('Failed to upload badges');
		}
	};

	// Handle image selection for a badge
	const handleBadgeImageChange = (e: React.ChangeEvent<HTMLInputElement>, badgeId: string) => {
		const file = e.target.files ? e.target.files[0] : null;
		setBadgeImages(prevState => ({
			...prevState,
			[badgeId]: file,
		}));
	};

	// Handle image upload for a badge
	const handleBadgeImageUpload = async (badge: BadgeInterface) => {
		const imageFile = badgeImages[badge._id];
		if (!imageFile) {
			toast.error('Please select an image for ' + badge.name);
			return;
		}

		const formData = new FormData();
		formData.append('image', imageFile);
		formData.append('code', badge.code);

		try {
			const headers = setHeaders({'Content-Type': 'multipart/form-data'});
			await axios.post(apiUrl + '/api/badges/admin/upload-badge-image', formData,
				headers);
			toast.success('Badge image uploaded successfully for ' + badge.name);
		} catch (error) {
			console.error('Error uploading image for ' + badge.name + ':', error);
			toast.error('Failed to upload badge image for ' + badge.name);
		}
	};

	return (
		<div className="p-4 max-w-7xl mx-auto bg-white rounded-xl shadow-md space-y-4">
			<AssignBadgesButton />
			<h1 className="text-2xl font-bold text-center">Badge Admin Page</h1>

			{/* CSV Upload Section */}
			<div className="space-y-2">
				<label className="block text-sm font-medium text-gray-700">Upload Badge CSV</label>
				<input type="file" accept=".csv" onChange={handleCsvChange} className="block w-full text-sm text-gray-500" />
				<button onClick={handleCsvUpload} className="mt-2 w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">
					Upload CSV
				</button>
			</div>

			{/* Badges List Section */}
			<div className="mt-6">
				<h2 className="text-xl font-semibold">Existing Badges</h2>
				<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3">
					{badges.map(badge => (
						<div key={badge._id} className="p-2 border-8 border-black rounded shadow-sm">
							<div className="flex items-center space-x-2">
								<img
									src={badgeImagesUrls[badge._id] || ''}
									alt={`${badge.name} icon`}
									className="w-20 h-20"
								/>
								<h3 className="text-md font-bold">{badge.name}</h3>
							</div>
							<p className="text-sm"><strong>Description:</strong> {badge.description}</p>
							<p className="text-sm"><strong>Code:</strong> {badge.code}</p>
							<p className="text-sm"><strong>Category:</strong> {badge.category}</p>
							<p className="text-sm"><strong>Value:</strong> {badge.value}</p>
							<p className="text-sm"><strong>Color:</strong> {badge.color}</p>
							<p className="text-sm"><strong>Icon Path:</strong> {badge.iconPath}</p>
							<p className="text-sm"><strong>Date Created:</strong> {new Date(badge.dateCreated).toLocaleString()}</p>
							<p className="text-sm"><strong>Is Unique:</strong> {badge.isUnique ? 'Yes' : 'No'}</p>
							{/* Option to upload image */}
							<div className="mt-1">
								<input
									type="file"
									accept="image/*"
									onChange={(e) => handleBadgeImageChange(e, badge._id)}
									className="text-sm"
								/>
								<button
									onClick={() => handleBadgeImageUpload(badge)}
									className="mt-1 w-full bg-green-500 text-white py-1 px-2 rounded hover:bg-green-600 text-sm"
								>
									Upload Image
								</button>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default BadgeAdminPage;
