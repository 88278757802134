import axios, { AxiosError } from 'axios';
import React, { useState, useEffect } from 'react';
import { url as apiUrl } from '../../../api'; // Ensure this path is correct for your project structure
import { RecipeMake } from '../items/recipe_make_card';

export type RecipeInfo = {
  title: string;
  id: string;
  createdBy: string;
  description: string;
  difficulty: number;
  time: string;
  dateAdded: Date;
  dateMade: Date;
  images: string[];
  ingredients: { name: string; quantity: string; }[];
  comments: RecipeMake[]
  instructions: string[];
};

export const useLoadRecipes = () => {
  const url = apiUrl as string; // Ensure proper type assertion
  const [recipes, setRecipes] = useState<RecipeInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [serverError, setServerError] = useState<boolean>(false);


  const fetchRecipes = async () => {
    setLoading(true);
    try {
      const response = await axios.get(url + `/api/recipes`);
      setRecipes(
        response.data.map((recipe: any) => ({
          title: recipe.title,
          createdBy: recipe.createdBy,
          id: recipe._id,
          description: recipe.description,
          dateAdded: new Date(recipe.dateAdded),
          dateMade: new Date(recipe.dateMade),
          time: recipe.time,
          difficulty: recipe.difficulty,
          images: recipe.images.map((filename: string) =>
            `${apiUrl}/api/recipes/images/${recipe._id}/${filename}`
          ),
          ingredients: recipe.ingredients,
          comments: recipe.comments.map((comment: any) => ({
            id: comment._id,
            author: comment.author,
            date: new Date(comment.date),
            comment: comment.comment,
            rating: comment.rating,
            isConfirmed: comment.confirmed,
            image: comment.image,
          })),
          instructions: recipe.instructions,
        }))
      );
      setLoading(false);
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.status === 404) {
        setRecipes([]);
        setLoading(false);
        console.log("No recipes found.");
      } else {
        console.error("Error fetching recipes:", axiosError);
        setServerError(true)
      }
    }
  };

  useEffect(() => {
    fetchRecipes();
  }, [url]);

  return { recipes, loading, serverError };
};
