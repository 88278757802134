import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ReactGA from 'react-ga4';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { BrowserRouter as Router, Routes } from 'react-router-dom';
import { Route } from 'react-router';
import { loadUser } from './store/actions/authActions';
import { createGlobalStyle, styled } from 'styled-components';
import Header from './components/site_header/site_header';
import Footer from './components/site_footer/site_footer';
import HomePage from './components/home_page/home';
import AboutPage from './components/about_page/about';
import ContactPage from './components/contact_page/contact';
import StorePage from './components/store_page/store_page'

import AppRoutes from './components/app_routes'
import { initializeGA } from './analytics/GoogleAnalytics';
import { fetchUncollectedBadges } from './store/actions/badgeActions';
import BadgeOverlayWrapper from './components/badges/badges_page/badgeOverlayWrapper';
// import GlobalBadgeOverlay from './components/badges/badges_page/globalBadgeOverlay';
// import { render } from '@testing-library/react';

initializeGA();

const GlobalStyle = createGlobalStyle`
  * {
    ${'' /* font-family: 'Fira Mono', monospace; */}
    font-family: 'JetBrains Mono', monospace;
    ${'' /* font-family: 'Roboto Mono', monospace; */}
    ${'' /* font-family: 'Source Code Pro', monospace; */}
    ${'' /* font-weight: 400; */}
  }
`;


function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUser())
    dispatch(fetchUncollectedBadges());
  }, [dispatch]);

  return (

    <Router>
      <ToastContainer
        autoClose={3000}
        closeOnClick
        draggable={true}
      />
      <GlobalStyle />
      <div className="App">
        <AppRoutes />
        <BadgeOverlayWrapper  />
        {/* <Header/>
        <div> */}
        {/* <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/marketplace" element={<StorePage />} />
            
          </Routes> */}
        {/* <AppRoutes/> */}
        {/* </div> */}

      </div>
    </Router>
  );
}


export default App;
