import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import Profile from "./profile";
import { useLocation } from "react-router";
import PreferencesBox from "./preferences-box";
import BadgeRewardOverlay from "../badges/badges_page/badgeRewardOverlay";
import BadgePage from "../badges/badges_page/badgePage";
import BadgeListing from "../badges/badges_page/badgeLisiting";
import TotalPoints from "../badges/badges_page/totalPoints";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #c9febe;
  align-items: center;
  padding: 15px;
  height: auto;
  min-height: 100vh;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #c9febe;
  align-items: center;
  padding: 15px;
  height: auto;
  min-height: 100vh;
  max-width: 900px;
`;

const Title = styled.h1`
  font-size: 2rem;
  color: #222;
  margin-bottom: 1rem;
  align-self: flex-start;
  margin-left: 30px;
  margin-bottom: 0px;
`;

const Description = styled.p`
  font-size: 1rem;
  color: #555;
  max-width: 800px;
  text-align: center;
  margin-top: -8px;
  /* margin-bottom: 2rem; */
`;

const ProfilePage: React.FC = () => {
  const location = useLocation();

  const showPreferencesBox = new URLSearchParams(location.search).get('p') === '1';

  


  useEffect(() => {
    document.title = `NΛSOS Your Profile`;
  }, []);

  return (
    <PageContainer>
      <ProfileContainer>
        <Title>Your Profile</Title>
        <Profile />
        <Description>
          Note that you can only change your username once. Don't overdo it.
          <br />
        </Description>
        <Title>Your Badges</Title>
        {/* <TotalPoints /> */}
        <BadgeListing />
        {showPreferencesBox && (
          <>
            <Title>AOTD Preferences</Title>
            <PreferencesBox />
            <Description>
              Here you can change your preferences for the Art of the Day.
            </Description>
          </>
        )}
      </ProfileContainer>
    </PageContainer>
  );
};

export default ProfilePage;
