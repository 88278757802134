import React, { useEffect, useState } from 'react';
import BadgeRewardOverlay from './badgeRewardOverlay';
import { url as apiUrl, setHeaders } from '../../../api';
import axios from 'axios';
import { toast } from 'react-toastify';
import BadgeCard from './badgeCard';

interface BadgeInfo {
  name: string;
  description: string;
  points: number;
  isUnique: boolean;
  color: string;
  collected: boolean;
  dateEarned: string;
  badgeId: string;
  badgeCode: string;
}

const BadgePage: React.FC = () => {
  const [badges, setBadges] = useState<BadgeInfo[]>([]);

  const fetchBadges = async () => {
    try {
      const headers = setHeaders();
      const response = await axios.get(`${apiUrl}/api/badges/all`, headers);

      const fetchedBadges = response.data.map((badgeItem: any) => ({
        name: badgeItem.badge.name,
        description: badgeItem.badge.description,
        points: badgeItem.badge.value,
        color: badgeItem.badge.color,
        isUnique: badgeItem.badge.isUnique,
        collected: badgeItem.collected,
        dateEarned: badgeItem.dateEarned,
        badgeId: badgeItem._id,
        badgeCode: badgeItem.badge.code,
      }));

      setBadges(fetchedBadges);
    } catch (error) {
      console.error('Error fetching badges:', error);
      toast.error('Failed to fetch badges');
    }
  };

  useEffect(() => {
    fetchBadges();
  }, []);

  return (
    <div className="min-h-scree py-8">
      <h1 className="text-3xl font-bold text-center mb-6">Badges</h1>
      <div className="container mx-auto px-8">
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-2">
          {badges.map((badge) => (
            <div key={badge.badgeId} className="p-2">
              <BadgeCard
                name={badge.name}
                points={badge.points}
                dateEarned={badge.dateEarned}
								description={badge.description}
                code={badge.badgeCode}
                // imageUrl={badge.imageUrl} // Ensure imageUrl is passed if available
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BadgePage;
