import axios from 'axios';
import React from 'react';
import { url as apiUrl, setHeaders } from '../../../api';
import { toast } from 'react-toastify';

interface BadgeCardProps {
	name: string;
	points: number;
	description: string;
	dateEarned: string;
	code: string;
	isUnique?: boolean;
	imageUrl?: string;
}

const BadgeCard: React.FC<BadgeCardProps> = ({
	name,
	points,
	dateEarned,
	code,
	description,
	isUnique: isUnique,
	imageUrl: propImageUrl,
}) => {
	const [imageUrl, setImageUrl] = React.useState<string | null>(propImageUrl || null);
	const [showModal, setShowModal] = React.useState(false);

	React.useEffect(() => {
		let isMounted = true;
		if (!propImageUrl) {
			const fetchImage = async () => {
				try {
					const headers = setHeaders({ responseType: 'blob' });
					const response = await axios.get(
						`${apiUrl}/api/badges/badge-image/${code}`,
						headers
					);
					const blob = new Blob([response.data], {
						type: response.headers['content-type'],
					});
					const imageObjectUrl = URL.createObjectURL(blob);
					if (isMounted) {
						setImageUrl(imageObjectUrl);
					}
				} catch (error) {
					console.error('Error fetching badge image:', error);
					toast.error('Failed to fetch badge image');
				}
			};
			fetchImage();
		}

		return () => {
			isMounted = false;
			if (imageUrl && !propImageUrl) {
				URL.revokeObjectURL(imageUrl);
			}
		};
	}, [code, propImageUrl]);

	return (
		<>
			<div className="relative inline-block">
				{imageUrl ? (
					<img
						src={imageUrl}
						alt={`${name} badge`}
						className="w-24 h-24 lg:w-40 lg:h-40 object-cover rounded-full"
					/>
				) : (
					<div className="w-24 h-24 lg:w-36 lg:h-36 bg-gray-200 rounded-full animate-pulse" />
				)}
				<button
					onClick={() => setShowModal(true)}
					className="absolute bottom-0 right-0 lg:bottom-5 lg:right-5 bg-white rounded-full p-1 shadow-md focus:outline-none"
					aria-label="More information"
				>
					<span className="text-blue-500 text-xl font-bold">i</span>
				</button>
			</div>

			{showModal && (
				<div
					className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
					onClick={() => setShowModal(false)}
				>
					<div
						className="bg-white rounded-lg p-6 max-w-sm w-full mx-4"
						onClick={(e) => e.stopPropagation()}
					>
						<div className="flex justify-between items-center align-top ">
							<h2 className="text-xl font-bold text-gray-800">{name}</h2>
							<button
								onClick={() => setShowModal(false)}

								className="text-gray-600 text-2xl font-bold focus:outline-none hover:text-gray-800 transition-colors duration-200"
								aria-label="Close modal"
							>
								&times;
							</button>
						</div>
						<div className="flex items-center space-x-4">
							{imageUrl ? (
								<img
									src={imageUrl}
									alt={`${name} badge`}
									className="w-24 h-24 lg:w-26 lg:h-26 object-cover rounded-full"
								/>
							) : (
								<div className="w-24 h-24 lg:w-26 lg:h-26 bg-gray-200 rounded-full animate-pulse" />
							)}
							<div>
								<div className="text-xl font-semibold text-gray-800">
									{points} Points {isUnique && <span className="text-pink-500">Unique</span>}
								</div>
								<div className="text-sm text-gray-500 mt-1">
									Earned on {new Date(dateEarned).toLocaleDateString()}
								</div>
							</div>
						</div>
						<p className="text-sm text-gray-600 leading-relaxed">{description}</p>
					</div>
				</div>
			)}
		</>
	);
};

export default BadgeCard;
