import React, { ChangeEvent, useState } from 'react';
import axios from 'axios';
import { StoreInfo } from './useInfo';
import { url } from 'src/api';



const PreOrderCard: React.FC<{ storeInfo: StoreInfo, email: string }> = ({ storeInfo, email }) => {
	const [uEmail, setUEmail] = useState(email || "");


	// const handleSubmit = async () => {
	// 	const orderData = {
	// 		buyerEmail: uEmail,
	// 		orderType: 'Preorder',
	// 		items: [
	// 			{
	// 				productId: storeInfo.id,
	// 				productTitle: storeInfo.title,
	// 				quantity: 1
	// 			}
	// 		],
	// 		shippingAddress: {
	// 			street: "-",
	// 			city: "-",
	// 			region: "-",
	// 			zip: "-",
	// 			country: "-"
	// 		}
	// 	};
	// 	try {
	// 		const headers = {
	// 			'Content-Type': 'application/json',
	// 			"x-auth-token": localStorage.getItem("token") 
	// 		};
	// 		const response = await axios.post(`${url}/api/orders/new`, orderData, { headers });
	// 		console.log(response.data);
	// 	} catch (error) {
	// 		console.error('Error submitting the order:', error);
	// 	}
	// };

	const handleSubmit = async () => {
		const email = uEmail;

		const data = {
			email
		};

		try {
			const response = await axios.post(`${url}/api/orders/get-in-list`, data);
			console.log(response.data);
		} catch (error) {
			console.error('Error submitting the preorder:', error);
		}
	}

	


	return (
		<div className='pre-order-card'>
			<div className="light-text">
				Pre orders available. Submit your order!
			</div>
			<div style={{ padding: "10px" }}>
				<label>email:</label>
				<input className="infoBox" value={uEmail} onChange={(e) => setUEmail(e.target.value)} />
			</div>
			<button style={{ backgroundColor: '#ff993a' }} className='selection-button' onClick={handleSubmit}>get in the list</button>
		</div>
	);
};

export default PreOrderCard;
